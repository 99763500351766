import { pxToRem } from '@/common/utils'
import { alertsData } from '@/data/AlertsData'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography,
} from '@mui/material'
import React from 'react'
import { BadgeStyle } from '../styles/BadgeStyle'
import { NotificationDetails } from './NotificationDetails'

type SmartContractEventType = {
  contractAddress?: string
  eventsAttributesMap?: [string, string][]
  eventName?: string
  txHash: string
  alertName?: string
  alertId: string
  chainId: string
}

export default function SmartContractEventView({
  contractAddress,
  eventsAttributesMap,
  eventName,
  txHash,
  alertName,
  alertId,
  chainId
}: SmartContractEventType) {
  const [expanded, setExpanded] = React.useState<string | false>(false)

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  const getFirstAndLastLetters = (str: string) => {
    return str.substring(0, 7) + '...' + str.substring(str.length - 4, str.length)
  }
  return (
    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
      <AccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Box
          sx={{
            display: 'flex',
            gap: '8px',
            width: '100%',
            overflow: 'hidden',
          }}
        >
          {alertsData.cosmosAlerts[1].icon}
          <Typography sx={{ width: 'auto', flexShrink: 0, paddingLeft: '8px' }}>
            {alertName}
          </Typography>
          <BadgeStyle>
            <span>{getFirstAndLastLetters(txHash)}</span>
          </BadgeStyle>
        </Box>
      </AccordionSummary>
      <Divider
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.12)',
        }}
      />
      <AccordionDetails
        sx={{
          paddingTop: pxToRem(24),
        }}
      >
        <NotificationDetails
          txHash={txHash}
          contractAddress={contractAddress}
          eventName={eventName}
          smartContractEvent={true}
          alertName={alertName}
          alertId={alertId}
          chainId={chainId}
        />
      </AccordionDetails>
    </Accordion>
  )
}
