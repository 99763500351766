import CreateDestination from '@/common/components/CreateDestination/CreateDestination'
import { DestinationType, FinishSetupDestination } from '@/common/types/common.types'
import { UserDestination } from '@/proto/api_pb'
import Popover from '@mui/material/Popover'

type DestinationPopoverProps = {
  onClosePopover: () => void
  open: boolean
  id?: string
  confirmationModalOpen: boolean
  onOpenModal: () => void
  onCloseModal: () => void
  onUpdateProposalDestination: (confirmProposalId: string, type: DestinationType, value: string) => void
  allUserDestinations: UserDestination.AsObject[]
  proposalDestination?: FinishSetupDestination
  clearNotFinishedDestination: () => void
  anchorEl: Element | ((element: Element) => Element) | null | undefined
}

export default function DestinationPopover({
  onClosePopover,
  open,
  id,
  confirmationModalOpen,
  onOpenModal,
  onCloseModal,
  onUpdateProposalDestination,
  allUserDestinations,
  proposalDestination,
  clearNotFinishedDestination,
  anchorEl
}: DestinationPopoverProps) {
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClosePopover}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      sx={{
        '& .MuiPopover-paper': {
          width: 'min-content',
          backgroundColor: '#3D3D3D',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          borderRadius: '12px',
          padding: '13px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'center',
        },
      }}
    >
      <CreateDestination
        columnView
        allUserDestinations={allUserDestinations}
        confirmationModalOpen={confirmationModalOpen}
        onOpenModal={onOpenModal}
        onCloseModal={onCloseModal}
        onUpdateProposalDestination={onUpdateProposalDestination}
        proposalDestination={proposalDestination}
        clearNotFinishedDestination={clearNotFinishedDestination}
        updateSelectIds={() => {}}
      />
    </Popover>
  )
}
