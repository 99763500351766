import { MonitorFundsTokenNotificationData } from '@/proto/api_pb'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  styled,
  Typography,
} from '@mui/material'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import React from 'react'
import { NotificationDetails } from './NotificationDetails'
import { pxToRem } from '@/common/utils'
import { alertsData } from '@/data/AlertsData'
import { BadgeStyle } from '../styles/BadgeStyle'

type TransactionOutcomeProps = {
  outcome?: string
  signer?: string
  txHash?: string
  alertName?: string
  alertId: string
  chainId: string
}

export default function TransactionOutcomeView({
  outcome,
  signer,
  txHash,
  alertName,
  alertId,
  chainId
}: TransactionOutcomeProps) {
  const [expanded, setExpanded] = React.useState<string | false>(false)

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  const getFirstAndLastLetters = (str: string) => {
    return str.substring(0, 7) + '...' + str.substring(str.length - 4, str.length)
  }
  return (
    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
      <AccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Box
          sx={{
            display: 'flex',
            gap: '8px',
            width: '100%',
            overflow: 'hidden',
          }}
        >
          {alertsData.cosmosAlerts[3].icon}
          <Typography sx={{ width: 'auto', flexShrink: 0, paddingLeft: '8px' }}>
            {alertName}
          </Typography>
          {signer && (
            <BadgeStyle>
              <span>{getFirstAndLastLetters(signer)}</span>
            </BadgeStyle>
          )}
          {outcome && (
            <BadgeStyle>
              <span>{getFirstAndLastLetters(outcome)}</span>
            </BadgeStyle>
          )}
        </Box>
      </AccordionSummary>
      <Divider
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.12)',
        }}
      />
      <AccordionDetails
        sx={{
          paddingTop: pxToRem(24),
        }}
      >
        <NotificationDetails
          txHash={txHash}
          outcome={outcome}
          signer={signer}
          transactionOutcome={true}
          alertName={alertName}
          alertId={alertId}
          chainId={chainId}
        />
      </AccordionDetails>
    </Accordion>
  )
}
