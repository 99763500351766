import { UserAlert } from '@/proto/api_pb'
import { colors } from '@/styles/colorScheme'
import styled from '@emotion/styled'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import * as React from 'react'
import { checkAlertType, getAlertRowData } from '../utils/Utils'
import AlertRow from './AlertRow'

type SelectAlertProps = {
  alerts: UserAlert.AsObject[]
  onUpdateAlert: (id: string, name: string, chainId: string) => void
  showMore: React.ReactNode
}

export default function SelectAlert({ alerts, onUpdateAlert, showMore }: SelectAlertProps) {
  const [selected, setSelected] = React.useState<string>('0')

  const handleChange = (event: SelectChangeEvent<string>, _: React.ReactNode) => {
    const {
      target: { value },
    } = event
    setSelected(value as unknown as string)
    onUpdateAlert(
      alerts[value as unknown as number].id,
      alerts[value as unknown as number].name,
      alerts[value as unknown as number].chainId
    )
  }

  return (
    <FormControl
      sx={{
        width: '100%',
      }}
    >
      <Select
        value={selected}
        onChange={handleChange}
        defaultValue={alerts ? alerts[0]?.id : '0'}
        input={<InputStyle label="Alert" />}
        MenuProps={{
          PaperProps: {
            sx: {
              background: '#3D3D3D',
              padding: '8px',
              borderRadius: '8px',
            },
          },
          MenuListProps: {
            sx: {
              background: '#3D3D3D',
              padding: '0px',
              '& .MuiMenuItem-root': {
                padding: '0px',
              },
            },
          },
        }}
        sx={{
          '& .MuiSelect-select': {
            padding: '0px',
          },
        }}
      >
        {alerts?.map((alert: UserAlert.AsObject, index: number) => {
          const cosmosAlert = alert.alert?.cosmosAlert
          const evmAlert = alert.alert?.cosmosEvmAlert
          const ethAlert = alert.alert?.ethAlert
          const cosmosEvmAlert = alert.alert?.cosmosEvmAlert

          const alertType = alert.alert && checkAlertType(alert.alert)

          return (
            <MenuItem
              key={alert.id}
              value={index}
              sx={{
                borderRadius: '8px',
                background: '#3D3D3D',
                color: colors.white.primary,
                '&.Mui-selected': {
                  backgroundColor: '#61E89F3D',
                  '& .MuiBox-root': {
                    '& .MuiTypography-root ': {
                      color: '#61E89F',
                    },
                  },
                },
                '&:hover': {
                  background: '#61E89F3D',
                },
              }}
            >
              <AlertRow
                key={alert.id}
                alertType={alertType}
                isSelect={true}
                status={alert.status}
                id={alert.id}
                alertRowData={getAlertRowData(alert)}
              />
            </MenuItem>
          )
        })}
        {showMore}
      </Select>
    </FormControl>
  )
}

const InputStyle = styled(OutlinedInput)(() => ({
  borderRadius: '8px',
  width: '100%',
  background: 'rgba(255, 255, 255, 0.12)',
  border: '1px solid transparent',
  color: colors.white.primary,
  '&:hover.MuiInputBase-root ': {
    border: '1px solid #61E89F',
  },
  '&.Mui-focused': {
    border: '1px solid #61E89F',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: 'none',
    '& legend': {
      display: 'none',
    },
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none',
    '& legend': {
      display: 'none',
    },
  },
  '& .MuiSvgIcon-root': {
    color: colors.white.primary,
  },
}))
