import { AlertTypeEnum, EthAlertTypeEnum, EvmAlertTypeEnum } from '@/common/enums/AlertTypeEnum'
import { alertsData } from '@/data/AlertsData'
import { Alert, AlertCosmosTxOutcome, AlertEthTxOutcome, UserAlert } from '@/proto/api_pb'

export function checkAlertType(alert: Alert.AsObject) {
  if (Boolean(alert.cosmosAlert)) {
    if (Boolean(alert.cosmosAlert?.alertCosmosMonitorFunds)) {
      return AlertTypeEnum.MONITOR_FUNDS
    } else if (Boolean(alert.cosmosAlert?.alertCosmosSendFunds)) {
      return AlertTypeEnum.SEND_FUNDS
    } else if (Boolean(alert.cosmosAlert?.alertCosmosSmartContractEvents)) {
      return AlertTypeEnum.SMART_CONTRACT_EVENTS
    } else if (Boolean(alert.cosmosAlert?.alertCosmosTxOutcome)) {
      return AlertTypeEnum.TX_OUTCOME
    }
  }
  if (Boolean(alert.cosmosEvmAlert)) {
    if (Boolean(alert.cosmosEvmAlert?.alertEthMonitorFunds)) {
      return EvmAlertTypeEnum.EVM_MONITOR_FUNDS
    } else if (Boolean(alert.cosmosEvmAlert?.alertEthSmartContractEvents)) {
      return EvmAlertTypeEnum.EVM_SMART_CONTRACT_EVENTS
    } else if (Boolean(alert.cosmosEvmAlert?.alertEthTxOutcome)) {
      return EvmAlertTypeEnum.EVM_TX_OUTCOME
    } else if (Boolean(alert.cosmosEvmAlert?.alertCosmosMonitorFunds)) {
      return EvmAlertTypeEnum.COSMOS_MONITOR_FUNDS
    } else if (Boolean(alert.cosmosEvmAlert?.alertCosmosTxOutcome)) {
      return EvmAlertTypeEnum.COSMOS_TX_OUTCOME
    }
  }
  if (Boolean(alert.ethAlert)) {
    if (Boolean(alert.ethAlert?.alertEthMonitorFunds)) {
      return EthAlertTypeEnum.ETH_MONITOR_FUNDS
    } else if (Boolean(alert.ethAlert?.alertEthSmartContractEvents)) {
      return EthAlertTypeEnum.ETH_SMART_CONTRACT_EVENTS
    } else if (Boolean(alert.ethAlert?.alertEthTxOutcome)) {
      return EthAlertTypeEnum.ETH_TX_OUTCOME
    }
  }
}

export function getAlertRowData(alert: UserAlert.AsObject): AlertRowData {
  let alertData: AlertRowData = {
    icon: alertsData.cosmosAlerts[0].icon
  }
  if (Boolean(alert.alert?.cosmosAlert)) {
    alert.alert?.cosmosAlert?.alertCosmosSendFunds ?
    alertData = {
      alertName: alert.name || 'Send Funds',
      setTo: alert.alert.cosmosAlert.alertCosmosSendFunds?.to,
      setFrom: alert.alert.cosmosAlert.alertCosmosSendFunds?.from,
      icon: alertsData.cosmosAlerts[0].icon
    } :
    alert.alert?.cosmosAlert?.alertCosmosMonitorFunds ?
    alertData ={
      alertName: alert.name || 'Monitor Funds',
      setAddress: alert.alert.cosmosAlert.alertCosmosMonitorFunds?.address,
      icon: alertsData.cosmosAlerts[0].icon
    } : 
    alert.alert?.cosmosAlert?.alertCosmosSmartContractEvents ?
    alertData = {
      alertName: alert.name || 'Smart Contract Events',
      setAddress: alert.alert.cosmosAlert.alertCosmosSmartContractEvents?.address,
      icon: alertsData.cosmosAlerts[2].icon
    } :
    alert.alert?.cosmosAlert?.alertCosmosTxOutcome ?
    alertData = {
      alertName: alert.name || 'Transaction Outcome',
      setAddress: alert.alert.cosmosAlert.alertCosmosTxOutcome?.signer,
      outcome: alert.alert.cosmosAlert.alertCosmosTxOutcome?.outcome,
      icon: alertsData.cosmosAlerts[3].icon
    } :
    {
      setAddress: '',
      icon: alertsData.cosmosAlerts[0].icon
    }
  } 
  if (Boolean(alert.alert?.cosmosEvmAlert)) {
    alert.alert?.cosmosEvmAlert?.alertEthMonitorFunds ?
    alertData = {
      alertName: alert.name || 'Monitor Funds',
      setAddress: alert.alert.cosmosEvmAlert.alertEthMonitorFunds?.address,
      icon: alertsData.cosmosAlerts[0].icon
    } :
    alert.alert?.cosmosEvmAlert?.alertEthSmartContractEvents ?
    alertData = {
      alertName: alert.name || 'Smart Contract Events',
      setAddress: alert.alert.cosmosEvmAlert.alertEthSmartContractEvents?.contractAddr,
      icon: alertsData.cosmosAlerts[2].icon
    } :
    alert.alert?.cosmosEvmAlert?.alertEthTxOutcome ?
    alertData = {
      alertName: alert.name || 'Transaction Outcome',
      setAddress: alert.alert.cosmosEvmAlert.alertEthTxOutcome?.signer,
      outcome: alert.alert.cosmosEvmAlert.alertEthTxOutcome?.outcome,
      icon: alertsData.cosmosAlerts[3].icon
    } :
    alert.alert?.cosmosEvmAlert?.alertCosmosMonitorFunds ?
    alertData = {
      alertName: alert.name || 'Monitor Funds',
      setAddress: alert.alert.cosmosEvmAlert.alertCosmosMonitorFunds?.address,
      icon: alertsData.cosmosAlerts[0].icon
    } :
    alert.alert?.cosmosEvmAlert?.alertCosmosTxOutcome ?
    alertData = {
      alertName: alert.name || 'Transaction Outcome',
      setAddress: alert.alert.cosmosEvmAlert.alertCosmosTxOutcome?.signer,
      outcome: alert.alert.cosmosEvmAlert.alertCosmosTxOutcome?.outcome,
      icon: alertsData.cosmosAlerts[3].icon
    } :
    {
      setAddress: '',
      icon: alertsData.cosmosAlerts[0].icon
    }
  }
  if (Boolean(alert.alert?.ethAlert)) {
    alert.alert?.ethAlert?.alertEthMonitorFunds ?
    alertData = {
      alertName: alert.name || 'Monitor Funds',
      setAddress: alert.alert.ethAlert.alertEthMonitorFunds?.address,
      icon: alertsData.cosmosAlerts[0].icon
    } :
    alert.alert?.ethAlert?.alertEthSmartContractEvents ?
    alertData = {
      alertName: alert.name || 'Smart Contract Events',
      setAddress: alert.alert.ethAlert.alertEthSmartContractEvents?.contractAddr,
      icon: alertsData.cosmosAlerts[2].icon
    } :
    alert.alert?.ethAlert?.alertEthTxOutcome ?
    alertData = {
      alertName: alert.name || 'Transaction Outcome',
      setAddress: alert.alert.ethAlert.alertEthTxOutcome?.signer,
      outcome: alert.alert.ethAlert.alertEthTxOutcome?.outcome,
      icon: alertsData.cosmosAlerts[3].icon
    } :
    {
      setAddress: '',
      icon: alertsData.cosmosAlerts[0].icon
    }
  }
  return alertData
}

export type AlertRowData = {
  setAddress?: string;
  setTo?: string;
  setFrom?: string;
  outcome?: AlertCosmosTxOutcome.CosmosTxOutcome | AlertEthTxOutcome.EthTxOutcome
  alertName?: string;
  icon: React.ReactComponentElement<any, Pick<any, string | number | symbol>>
}