import { AlertNotificationData } from '@/proto/api_pb'
import Accordion from '@mui/material/Accordion'
import React from 'react'
import MonitorFundsView from './MonitorFundsView'
import SmartContractEventView from './SmartContractEventView'
import TransactionOutcomeView from './TransactionOutcomeView'

type NotificationRowProps = {
  alertData?: AlertNotificationData.AsObject
  alertName: string
  alertId: string
  chainId: string
}

export default function NotificationRow({ alertData, alertName, alertId, chainId }: NotificationRowProps) {
  return (
    <>
      {(alertData?.monitorFundsToken || alertData?.monitorFundsCoin) && (
        <MonitorFundsView
          from={
            alertData?.monitorFundsToken
              ? alertData?.monitorFundsToken.from
              : alertData?.monitorFundsCoin?.from
          }
          to={
            alertData?.monitorFundsToken
              ? alertData?.monitorFundsToken.to
              : alertData?.monitorFundsCoin?.to
          }
          amount={alertData?.monitorFundsToken ? alertData?.monitorFundsToken.amount : ''}
          txHash={
            alertData?.monitorFundsToken
              ? alertData?.monitorFundsToken.txHash
              : alertData?.monitorFundsCoin?.txHash
          }
          contractAddr={
            alertData?.monitorFundsToken ? alertData?.monitorFundsToken.contractAddr : ''
          }
          alertName={alertName}
          alertId={alertId}
          chainId={chainId}
        />
      )}
      {alertData?.scEvents && (
        <SmartContractEventView
          contractAddress={alertData?.scEvents?.contractAddr}
          eventsAttributesMap={alertData?.scEvents?.eventAttributesMap}
          eventName={alertData?.scEvents?.eventName}
          txHash={alertData?.scEvents?.txHash}
          alertName={alertName}
          alertId={alertId}
          chainId={chainId}
        />
      )}
      {alertData?.txOutcome && (
        <TransactionOutcomeView
          outcome={alertData?.txOutcome?.outcome}
          signer={alertData?.txOutcome?.signer}
          txHash={alertData?.txOutcome?.txHash}
          alertName={alertName}
          alertId={alertId}
          chainId={chainId}
        />
      )}
    </>
  )
}
