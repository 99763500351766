import { Box, IconButton, styled, Typography } from '@mui/material'
import { ReactComponent as CopyIcon } from '@/assets/icons/Copy.svg'
import { ReactComponent as LinkIcon } from '@/assets/icons/Link.svg'
import { typography } from '@/styles/typography'
import { chainsData } from '@/data/ChainsData'

type NotificationDetailsRowProps = {
  title: string
  value?: string
  isLink?: boolean
  chainId?: string
}

export const NotificationDetailsRow = ({
  title,
  value,
  isLink,
  chainId,
}: NotificationDetailsRowProps) => {
  const selected = chainsData.find(chain => chain.id === chainId)
  const explorerLink = selected?.explorerLink as string + value

  return (
    <Box
      width={1}
      display={'flex'}
      flexWrap={'wrap'}
      sx={theme => ({
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
        },
      })}
    >
      <BodyTextStyled width={150}>{title}</BodyTextStyled>
      {isLink ? (
        <Box display={'flex'}>
          <LinkStyled href={explorerLink} target={"_blank"}>
            <Typography
              sx={{
                color: '#61E89F',
              }}
            >
              {value}
            </Typography>
            <LinkIcon className="icon" />
          </LinkStyled>
          <IconButton
            sx={{
              padding: '0px 8px',
            }}
            onClick={() => {
              navigator.clipboard.writeText(value ?? '')
            }}
          >
            <CopyIcon className="icon" />
          </IconButton>
        </Box>
      ) : (
        <Box display={'flex'}>
          <Typography>{value}</Typography>
          <IconButton
            sx={{
              padding: '0px 8px',
            }}
            onClick={() => {
              navigator.clipboard.writeText(value ?? '')
            }}
          >
            <CopyIcon className="icon" />
          </IconButton>
        </Box>
      )}
    </Box>
  )
}

const BodyTextStyled = styled(Typography)(() => ({
  ...typography.bodyBig,
  color: 'rgba(255, 255, 255, 0.48)',
}))

const BadgeStyled = styled(Typography)(() => ({
  backgroundColor: '#61E89F3D',
  color: '#61E89F',
  padding: '2px 4px',
  borderRadius: '4px',
  margin: 'auto 0',
}))

const LinkStyled = styled('a')(() => ({
  display: 'flex',
  gap: '2px',
  alignItems: 'center',
  textDecoration: 'none',
  color: '#61E89F',
  '&:hover': {
    textDecoration: 'underline',
  },
  '& .icon': {
    '& path': {
      fill: '#61E89F',
    },
  },
}))
