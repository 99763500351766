import { ReactComponent as Plus } from '@/assets/icons/Plus.svg'
import useCommonDestinationState from '@/common/hooks/useCommonDestinationState'
import { pxToRem } from '@/common/utils'
import { useAuth } from '@/contexts/AuthContext'
import { AuthContextType } from '@/contexts/AuthContextType'
import DestinationPopover from '@/modules/dashboard/components/DestinationPopover'
import LoadingScreen from '@/modules/loadingScreen/components/LoadingScreen'
import { userService } from '@/service/UserService'
import { colors } from '@/styles/colorScheme'
import styled from '@emotion/styled'
import AddIcon from '@mui/icons-material/Add'
import { Box, Button, Tab } from '@mui/material'
import Tabs from '@mui/material/Tabs'
import Link from 'next/link'
import { useCallback, useEffect, useState } from 'react'
import useDashboard from '../hooks/useDashboard'
import ActivityView from './ActivityView'
import AlertsView from './AlertsView'
import DestinationView from './DestinationView'

export default function HomeDashboardView() {
  const {
    value,
    handleChange,
    openPopover,
    closePopover,
    loading,
    alerts,
    nextPage,
    nextPageLoading,
    isPopoverOpen,
    idPopover,
    anchorEl,
    setAnchorEl,
    deleteDestination,
    deleteAlert,
  } = useDashboard()
  const {
    confirmationModalOpen,
    openModal,
    closeModal,
    updateProposalDestination,
    proposalDestination,
    clearNotFinishedDestination,
  } = useCommonDestinationState()

  const [allUserDestinations, setAllUserDestinations] = useState([])
  const { userToken, userId } = useAuth() as AuthContextType

  const userDestinations = useCallback(async () => {
    if (!userId || !userToken) return

    const response = await userService.getUserDestinations(userId, userToken)
    if (response?.status == 'FAIL') {
      return
    }
    if (response.status == 'SUCCESS') {
      setAllUserDestinations(response.data.userDestinationsList)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proposalDestination])

  useEffect(() => {
    userDestinations()
  }, [userDestinations])

  const handleClose = () => {
    closePopover()
    closeModal()
  }

  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    openPopover()
  }

  const handleOpen = () => {
    openPopover()
    openModal()
  }

  const onDeleteDestination = async (id: string) => {
    await deleteDestination(id)
    userDestinations()
  }

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          '@media (max-width: 768px)': {
            flexWrap: 'wrap',
            justifyContent: 'center',
          },
        }}
      >
        <StyledTabs value={value} onChange={handleChange}>
          <StyledTab label="Activity" />
          <StyledTab label="Alerts" />
          <StyledTab label="Destinations" />
        </StyledTabs>
        <Box
          sx={{
            marginLeft: 'auto',
            '@media (max-width: 768px)': {
              marginLeft: 0,
            },
          }}
        >
          <Link href={'/create'}>
            {value != 2 && <StyledButton startIcon={<Plus />}>Create Alert</StyledButton>}
          </Link>
          {value === 2 && (
            <>
              <DestinationStyledButton
                onClick={handleOpenPopover}
                startIcon={<AddIcon fill="#8C8C8C" />}
              >
                Add Destination
              </DestinationStyledButton>
              <DestinationPopover
                id={idPopover}
                open={isPopoverOpen}
                anchorEl={anchorEl}
                onClosePopover={closePopover}
                confirmationModalOpen={confirmationModalOpen}
                onOpenModal={openModal}
                onCloseModal={handleClose}
                onUpdateProposalDestination={updateProposalDestination}
                allUserDestinations={allUserDestinations}
                proposalDestination={proposalDestination}
                clearNotFinishedDestination={clearNotFinishedDestination}
              />
            </>
          )}
        </Box>
      </Box>
      <>
        {loading ? (
          <LoadingScreen />
        ) : (
          <>
            {value === 0 && (
              <ActivityView
                alerts={alerts}
                nextPageLoading={nextPageLoading}
                nextPage={nextPage}
                hasNextPage={isDivisibleBy20(alerts?.length)}
              />
            )}
            {value === 1 && (
              <AlertsView
                alerts={alerts}
                nextPage={nextPage}
                nextPageLoading={nextPageLoading}
                hasNextPage={isDivisibleBy20(alerts?.length)}
                deleteAlert={deleteAlert}
              />
            )}
            {value === 2 && (
              <DestinationView
                allUserDestinations={allUserDestinations}
                notFinishedDestination={proposalDestination}
                onOpenModal={handleOpen}
                clearNotFinishedDestination={clearNotFinishedDestination}
                deleteDestination={onDeleteDestination}
              />
            )}
          </>
        )}
      </>
    </Box>
  )
}

const isDivisibleBy20 = (num: number) => {
  return num % 20 === 0
}

const StyledTabs = styled(Tabs)(() => ({
  '& .MuiTabs-flexContainer': {
    gap: '4px',
    flexWrap: 'wrap',
    minHeight: 'none',
    '@media (max-width: 768px)': {
      padding: pxToRem(10),
      justifyContent: 'flex-start',
    },
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
}))

const StyledTab = styled(Tab)(() => ({
  '&.MuiTab-root': {
    height: 'fit-content',
    minHeight: '40px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '8px 16px',
    gap: '6px',
    borderRadius: '20px',
    textTransform: 'none',
    fontSize: '16px',
    border: '1px solid #FFFFFF3D',
    color: colors.white.primary,
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.12)',
    },
    '&.Mui-selected': {
      background: '#61E89F3D',
      color: '#61E89F',
      border: 'none',
    },
    '&.MuiTab-iconWrapper': {
      marginBottom: '0px',
    },
    '&:disabled': {
      backgroundColor: 'rgba(255, 255, 255, 0.12)',
      // color: 'rgba(255, 255, 255, 0.24)',
    },
  },
}))

const StyledButton = styled(Button)(() => ({
  width: 'fit-content',
  padding: '6px 16px',
  gap: pxToRem(8),
  borderRadius: '8px',
  fontSize: '14px',
  color: colors.black,
  backgroundColor: colors.green.primary,
  '&:hover': {
    backgroundColor: '#4EBA7F',
  },
}))

const DestinationStyledButton = styled(Button)(() => ({
  width: 'fit-content',
  gap: pxToRem(4),
  border: '1px solid #FFFFFF3D',
  color: 'white',
  borderRadius: '8px',
  padding: '6px 12px',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.24)',
  },
}))
