import { Box, CircularProgress } from '@mui/material'
import React from 'react'

export default function LoadingScreen() {
  return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '50vh',
          margin: 'auto',
        }}
      >
        <CircularProgress
          sx={{
            color: '#61E89F',
          }}
        />
      </Box>
  )
}
