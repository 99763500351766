import { pxToRem } from '@/common/utils'
import { AlertCosmosTxOutcome, AlertEthTxOutcome, UserAlert } from '@/proto/api_pb'
import styled from '@emotion/styled'
import { Button, CircularProgress } from '@mui/material'
import AlertRow from '../components/AlertRow'
import { checkAlertType, getAlertRowData } from '../utils/Utils'

type AlertsViewProps = {
  alerts: UserAlert.AsObject[]
  nextPage: () => void
  nextPageLoading: boolean
  hasNextPage: boolean
  deleteAlert: (id: string) => void
}

export default function AlertsView({
  alerts,
  nextPage,
  nextPageLoading,
  hasNextPage,
  deleteAlert,
}: AlertsViewProps) {
  console.log("user Alerts", alerts)
  return (
    <Container>
      {alerts?.map((alert: UserAlert.AsObject) => {
        const cosmosAlert = alert.alert?.cosmosAlert
        const evmAlert = alert.alert?.cosmosEvmAlert
        const ethAlert = alert.alert?.ethAlert
        const cosmosEvmAlert = alert.alert?.cosmosEvmAlert

        const setAddress =
          cosmosAlert?.alertCosmosMonitorFunds?.address ||
          cosmosAlert?.alertCosmosSmartContractEvents?.address ||
          cosmosAlert?.alertCosmosTxOutcome?.signer ||
          evmAlert?.alertEthTxOutcome?.signer ||
          evmAlert?.alertCosmosMonitorFunds?.address ||
          evmAlert?.alertCosmosTxOutcome?.signer ||
          evmAlert?.alertEthMonitorFunds?.address ||
          evmAlert?.alertEthSmartContractEvents?.contractAddr ||
          ethAlert?.alertEthMonitorFunds?.address ||
          ethAlert?.alertEthSmartContractEvents?.contractAddr ||
          ethAlert?.alertEthTxOutcome?.signer

        const outcome =
          cosmosAlert?.alertCosmosTxOutcome?.outcome !== undefined
            ? cosmosAlert?.alertCosmosTxOutcome?.outcome
            : evmAlert?.alertCosmosTxOutcome?.outcome !== undefined
            ? evmAlert?.alertCosmosTxOutcome?.outcome
            : ethAlert?.alertEthTxOutcome?.outcome !== undefined
            ? ethAlert?.alertEthTxOutcome?.outcome
            : cosmosEvmAlert?.alertCosmosTxOutcome?.outcome !== undefined
            ? cosmosEvmAlert?.alertCosmosTxOutcome?.outcome
            : cosmosEvmAlert?.alertEthTxOutcome?.outcome

        const alertType = alert.alert && checkAlertType(alert.alert)

        const smartContractEventName = cosmosAlert?.alertCosmosSmartContractEvents
          ? cosmosAlert?.alertCosmosSmartContractEvents?.eventName
          : evmAlert?.alertEthSmartContractEvents
          ? evmAlert?.alertEthSmartContractEvents?.eventName
          : ethAlert?.alertEthSmartContractEvents
          ? ethAlert?.alertEthSmartContractEvents?.eventName
          : ''

        const smartContractEvents = cosmosAlert?.alertCosmosSmartContractEvents
          ? cosmosAlert?.alertCosmosSmartContractEvents?.eventAttributesMap
          : evmAlert?.alertEthSmartContractEvents
          ? evmAlert?.alertEthSmartContractEvents?.eventAttributesMap
          : ethAlert?.alertEthSmartContractEvents?.eventAttributesMap

        return (
          <AlertRow
            destinations={alert.destinationIdsList}
            key={alert.id}
            alertType={alertType}
            status={alert.status}
            id={alert.id}
            deleteAlert={deleteAlert}
            chainId={alert.chainId}
            contractAbi={ethAlert?.alertEthSmartContractEvents?.contractAbi}
            scEventName={smartContractEventName}
            smartContractEvents={smartContractEvents}
            alertRowData={getAlertRowData(alert)}
          />
        )
      })}
      <>
        {hasNextPage && (
          <Button
            sx={{
              color: '#FFF',
              background: 'transparent',
              borderRadius: '8px',
              padding: '8px 16px',
              border: '1px solid #FFFFFF3D',
              width: 'auto',
            }}
            onClick={nextPage}
          >
            {nextPageLoading && (
              <CircularProgress
                sx={{
                  color: '#FFF',
                  width: '15px !important',
                  height: '15px !important',
                  marginRight: '10px',
                }}
              />
            )}{' '}
            Show More
          </Button>
        )}
      </>
    </Container>
  )
}

const Container = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: pxToRem(20),
  padding: `${pxToRem(20)} 0 ${pxToRem(50)}`,
}))
