import { alertMessages } from '@/common/utils/alert-messages'
import { useAuth } from '@/contexts/AuthContext'
import { AuthContextType } from '@/contexts/AuthContextType'
import { useSnackbar } from '@/contexts/SnackbarContext'
import { SnackBarContextType } from '@/contexts/SnackbarContextType'
import { UserAlert } from '@/proto/api_pb'
import { userService } from '@/service/UserService'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import { useBoolean } from 'usehooks-ts'

export default function useDashboard() {
  const [value, setValue] = useState(0)
  const [alerts, setAlerts] = useState<UserAlert.AsObject[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [page, setPage] = useState<number>(0)
  const [nextPageLoading, setNextPageLoading] = useState<boolean>(false)
  const router = useRouter()
  const { addAlert } = useSnackbar() as SnackBarContextType
  const { userToken, userId } = useAuth() as AuthContextType

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
    setPage(0)
    setLoading(true)
  }

  const getAlertsByUser = useCallback(
    async (page?: number) => {
      if (!userId || !userToken) {
        router.push('/login')
        return
      }
      return await userService.getAlertsByUser(userId, userToken, page)
    },
    [userId, userToken, router]
  )

  const getAlerts = useCallback(async () => {
    const response = await getAlertsByUser()
    if (response?.status == 'FAIL') {
      setAlerts([])
      return
    }
    const userAlerts: UserAlert.AsObject[] = response?.data.alertsList
    if (userAlerts?.length == 0) {
      router.push('/init')
    }
    setLoading(false)
    setAlerts(response?.data.alertsList)
  }, [getAlertsByUser, router])

  const nextPage = useCallback(async () => {
    setNextPageLoading(true)
    const newPage = page + 1
    const response = await getAlertsByUser(newPage)
    setPage(newPage)
    setNextPageLoading(false)
    setAlerts([...alerts, ...response?.data.alertsList])
  }, [alerts, getAlertsByUser, page])

  const deleteDestination = useCallback(
    async (id: string) => {
      if (!userId || !userToken) {
        router.push('/login')
        return
      }
      const response = await userService.deleteDestination(id, userId, userToken)
      if (response.status == 'SUCCESS') {
        getAlerts()
        addAlert(alertMessages.destinations.destinationDeleted)
      }
    },
    [addAlert, getAlerts, router, userId, userToken]
  )

  const deleteAlert = useCallback(
    async (id: string) => {
      if (!userId || !userToken) {
        router.push('/login')
        return
      }
      const response = await userService.deleteAlert(id, userToken)
      if (response.status == 'SUCCESS') {
        getAlerts()
        addAlert(alertMessages.alerts.alertDeleted)
      }
    },
    [addAlert, getAlerts, router, userId, userToken]
  )

  useEffect(() => {
    getAlerts()
  }, [getAlerts, value])

  const { value: isPopoverOpen, setTrue, setFalse } = useBoolean(false)

  const idPopover = isPopoverOpen ? 'simple-popover' : undefined

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  return {
    value,
    handleChange,
    openPopover: setTrue,
    closePopover: setFalse,
    isPopoverOpen,
    loading,
    alerts,
    nextPage,
    nextPageLoading,
    idPopover,
    anchorEl,
    setAnchorEl,
    deleteDestination,
    deleteAlert,
  }
}
