import { typography } from '@/styles/typography'
import { Box, Button, styled, Typography } from '@mui/material'
import { NotificationDetailsRow } from './NotificationDetailsRow'
import { ReactComponent as Edit} from '@/assets/icons/Edit.svg'

type NotificationDetailsProps = {
  txHash?: string
  from?: string
  to?: string
  amount?: string
  transactionOutcome?: boolean
  contractAddress?: string
  eventName?: string
  smartContractEvent?: boolean
  monitorFounds?: boolean
  outcome?: string
  signer?: string
  alertName?: string
  alertId: string
  chainId: string
}

export const NotificationDetails = ({
  txHash,
  from,
  to,
  amount,
  transactionOutcome,
  contractAddress,
  eventName,
  smartContractEvent,
  monitorFounds,
  outcome,
  signer,
  alertName,
  alertId,
  chainId
}: NotificationDetailsProps) => {
  return (
    <Box display={'flex'} flexDirection="column" gap={'28px'} flexWrap={'wrap'}>
      <Box display={'flex'} gap={'12px'} flexWrap={'wrap'} width={1}>
        <Box display="flex" width={'100%'} justifyContent="space-between">
          <TitleStyled>{alertName}</TitleStyled>
          {/* <Button
            sx={{
              width: 'auto',
              display: 'flex',
              borderRadius: '8px',
              padding: '12px',
              gap: '8px',
              fontSize: '16px',
              fontWeight: 500,
              color: "#FFFFFF7A",
              backgroundColor: "transparent",
              '&:hover': {
                backgroundColor: '#FFFFFF1F',
              },
            }}
          >
            <Edit />
            Edit Alert
          </Button> */}
        </Box>
        {monitorFounds && (
          <Typography
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.12)',
              padding: '2px 4px',
              borderRadius: '4px',
            }}
          >
            {from}
          </Typography>
        )}
      </Box>
      <Box display={'flex'} flexDirection="column" gap={'8px'}>
        <Box display={'flex'}>
          <BodyTextStyled width={150}>Status</BodyTextStyled>
          <BadgeStyled>Confirmed</BadgeStyled>
        </Box>
        <NotificationDetailsRow title={'Transaction hash'} value={txHash} isLink={true} chainId={chainId} />
        {monitorFounds && (
          <>
            <NotificationDetailsRow title={'From'} value={from} />
            <NotificationDetailsRow title={'To'} value={to} />
            {/* <NotificationDetailsRow title={'Amount'} value={amount} /> */}
          </>
        )}
        {smartContractEvent && (
          <>
            <NotificationDetailsRow title={'Contract Address'} value={contractAddress} />
            <NotificationDetailsRow title={'Event Name'} value={eventName} />
          </>
        )}
        {transactionOutcome && (
          <>
            <NotificationDetailsRow title={'Outcome'} value={outcome} />
            <NotificationDetailsRow title={'Signer'} value={signer} />
          </>
        )}
      </Box>
    </Box>
  )
}

const TitleStyled = styled(Typography)(() => ({
  ...typography.titleRegular,
}))

const BodyTextStyled = styled(Typography)(() => ({
  ...typography.bodyBig,
  color: 'rgba(255, 255, 255, 0.48)',
}))

const BadgeStyled = styled(Typography)(() => ({
  backgroundColor: '#61E89F3D',
  color: '#61E89F',
  padding: '2px 4px',
  borderRadius: '4px',
  margin: 'auto 0',
}))
