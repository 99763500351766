import { styled } from "@mui/material";

export const BadgeStyle = styled('div')(
    () => `
        background-color: rgba(255, 255, 255, 0.12);
        border-radius: 4px;
        display: flex;
        flex-wrap: wrap;
        border-radius: 8px;
        padding: 2px 10px;
        color: white;
        font-size: 14px;
        `
  )