import { ReactComponent as PoligonsBW } from '@/assets/icons/PoligonsBW.svg'
import { pxToRem } from '@/common/utils'
import { useAuth } from '@/contexts/AuthContext'
import { AuthContextType } from '@/contexts/AuthContextType'
import { AlertNotification, UserAlert } from '@/proto/api_pb'
import { userService } from '@/service/UserService'
import styled from '@emotion/styled'
import { Box, Button, CircularProgress, Divider, Typography } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import NotificationRow from '../components/NotificationRow'
import SelectAlert from '../components/SelectAlert'

type ActivityViewProps = {
  alerts: UserAlert.AsObject[]
  nextPage: () => void
  nextPageLoading: boolean
  hasNextPage: boolean
}

type SelectedAlertProps = {
  id: string
  name: string
  chainId: string
}

export default function ActivityView({
  alerts,
  nextPage,
  nextPageLoading,
  hasNextPage,
}: ActivityViewProps) {
  const [selectedAlert, setSelectedAlert] = useState<SelectedAlertProps>({
    id: alerts ? alerts[0]?.id : '0',
    name: alerts ? alerts[0]?.name : 'Alert does not have a name',
    chainId: alerts ? alerts[0]?.chainId : '0',
  })
  const [notifications, setNotifications] = useState<AlertNotification.AsObject[]>([])
  const { userToken } = useAuth() as AuthContextType
  const [page, setPage] = useState(0)

  const hasNextPageNotifications = useMemo(() => {
    return (
      notifications.length === 20 ||
      notifications.length === 40 ||
      notifications.length === 60 ||
      notifications.length === 80
    )
  }, [notifications])

  const alertNotifications = useCallback(async () => {
    if (selectedAlert.id) {
      if (typeof userToken !== 'string') {
        //Can add a snackbar here
        return
      }

      setPage(0)
      const response = await userService.getNotificationByAlert(selectedAlert.id, userToken)

      if (response.status == 'SUCCESS') {
        setNotifications(response.data.notificationsList)
      }
    }
  }, [selectedAlert.id, userToken])

  const nextNotificationPage = async () => {
    if (!userToken) return

    const response = await userService.getNotificationByAlert(selectedAlert.id, userToken, page + 1)
    if (response.status == 'SUCCESS') {
      setNotifications(prev => [...prev, ...response.data.notificationsList])
    }
    setPage(page + 1)
  }

  const updateAlert = (id: string, name: string, chainId: string) => {
    setSelectedAlert({ id, name, chainId })
  }

  useEffect(() => {
    alertNotifications()
  }, [alertNotifications])

  const ShowMoreButton = () => {
    return (
      <>
        {hasNextPage && (
          <Button
            sx={{
              color: '#FFF',
              background: 'transparent',
              borderRadius: '8px',
              padding: '8px 16px',
              border: '1px solid #FFFFFF3D',
              width: 'auto',
            }}
            onClick={nextPage}
          >
            {nextPageLoading && (
              <CircularProgress
                sx={{
                  color: '#FFF',
                  width: '15px !important',
                  height: '15px !important',
                  marginRight: '10px',
                }}
              />
            )}{' '}
            Show More
          </Button>
        )}
      </>
    )
  }

  return (
    <Container>
      <SelectAlert alerts={alerts} onUpdateAlert={updateAlert} showMore={<ShowMoreButton />} />
      <Box mt={'30px'} width={'100%'}>
        <Typography
          sx={{
            color: 'white',
            marginBottom: '10px',
            fontSize: pxToRem(22),
            fontWeight: 600,
          }}
        >
          {notifications.length > 0 && notifications.length + ' Alert'}
          {notifications.length > 1 && 's'}
        </Typography>
        <Box gap={1.5} display="flex" flexDirection={'column'}>
          {notifications.map((notification: AlertNotification.AsObject, index: number) => {
            return (
              <NotificationRow
                key={index}
                alertData={notification.notificationData}
                alertName={selectedAlert.name}
                alertId={selectedAlert.id}
                chainId={selectedAlert.chainId}
              />
            )
          })}
          {hasNextPageNotifications && (
            <Button
              sx={{
                color: '#FFF',
                background: 'transparent',
                borderRadius: '8px',
                padding: '8px 16px',
                border: '1px solid #FFFFFF3D',
                width: 'auto',
              }}
              onClick={nextNotificationPage}
            >
              {nextPageLoading && (
                <CircularProgress
                  sx={{
                    color: '#FFF',
                    width: '15px !important',
                    height: '15px !important',
                    marginRight: '10px',
                  }}
                />
              )}{' '}
              Show More
            </Button>
          )}
          {notifications.length == 0 && (
            <>
              <Divider
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.12)',
                }}
              />
              <Typography
                sx={{
                  color: '#FFFFFF7A',
                  marginBottom: '10px',
                  fontSize: pxToRem(16),
                  fontWeight: 600,
                }}
              >
                No alerts yet
              </Typography>
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <PoligonsBW />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Container>
  )
}

const Container = styled.div(() => ({
  width: '100%',
  display: 'flex',
  marginTop: '1.25rem',
  flexDirection: 'column',
  alignItems: 'flex-start',
}))
