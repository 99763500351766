import { useAuth } from '@/contexts/AuthContext'
import { AuthContextType } from '@/contexts/AuthContextType'
import PageLayout from '@/layouts/PageLayout'
import HomeDashboardView from '@/modules/dashboard/views/HomeDashboard.View'
import LoadingScreen from '@/modules/loadingScreen/components/LoadingScreen'
import { UserAlert } from '@/proto/api_pb'
import { userService } from '@/service/UserService'
import { Box, styled } from '@mui/material'
import { useRouter } from 'next/router'
import { useCallback, useState } from 'react'
import { useEffectOnce, useUpdateEffect } from 'usehooks-ts'

export default function Home() {
  const router = useRouter()
  const [alerts, setAlerts] = useState<UserAlert.AsObject[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const { userToken, userId } = useAuth() as AuthContextType
  const getAlertsByUser = useCallback(
    async (page?: number) => {
      if (!userId || !userToken) {
        router.push('/login')
        return
      }
      const result = await userService.getAlertsByUser(userId, userToken, page)
      if (result?.status == 'FAIL') {
        return
      }
      setAlerts(result?.data.alertsList)
    },
    [router, userId, userToken]
  )

  useEffectOnce(() => {
    getAlertsByUser()
  })

  useUpdateEffect(() => {
    if (alerts.length === 0) {
      router.push('/init')
    } else {
      setLoading(false)
    }
  }, [alerts, router])

  return (
    <PageLayout gradient={<Gradient />} showSignButton={false}>
      {loading ? <LoadingScreen /> : <HomeDashboardView />}
      {/* <MaintenanceView /> */}
    </PageLayout>
  )
}

const Gradient = styled(Box)(() => ({
  background: 'radial-gradient(50% 64.61% at 50% 0%, #0E3112 0%, #0C0D2C 52.48%, #000000 100%)',
  position: 'absolute',
  width: '100%',
  height: '420px',
  left: '0',
  right: '0',
  margin: '0 auto',
  top: '0px',
  zIndex: 0,
}))
