import DeleteConfirmationModal from '@/common/components/DeleteConfirmationModal/DeleteConfirmationModal'
import { AlertTypeEnum, EthAlertTypeEnum, EvmAlertTypeEnum } from '@/common/enums/AlertTypeEnum'
import { EditAlertData } from '@/common/types/common.types'
import { alertMessages } from '@/common/utils/alert-messages'
import { useAuth } from '@/contexts/AuthContext'
import { AuthContextType } from '@/contexts/AuthContextType'
import { useSnackbar } from '@/contexts/SnackbarContext'
import { SnackBarContextType } from '@/contexts/SnackbarContextType'
import { UserAlert } from '@/proto/api_pb'
import { userService } from '@/service/UserService'
import styled from '@emotion/styled'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Box, IconButton, Switch, SwitchProps, Typography } from '@mui/material'
import Router from 'next/router'
import React, { useState } from 'react'
import { AlertRowData } from '../utils/Utils'
import AlertPopover from './AlertPopover'

type AlertRowProps = {
  status: UserAlert.Status
  id: string
  alertType?: AlertTypeEnum | EvmAlertTypeEnum | EthAlertTypeEnum
  isSelect?: boolean
  deleteAlert?: (id: string) => void
  destinations?: string[]
  chainId?: string
  contractAbi?: string
  scEventName?: string
  smartContractEvents?: [string, string][]
  alertRowData: AlertRowData
}

export default function AlertRow({
  status,
  id,
  alertType,
  isSelect,
  destinations,
  deleteAlert,
  chainId,
  contractAbi,
  scEventName,
  smartContractEvents,
  alertRowData,
}: AlertRowProps) {
  const [currentStatus, setCurrentStatus] = React.useState<UserAlert.Status>(status)
  const { addAlert } = useSnackbar() as SnackBarContextType
  const { userToken } = useAuth() as AuthContextType
  

  const smartContractEventsArray: string[] = smartContractEvents
    ? smartContractEvents.map(event => {
        return `${event[0]}:${event[1]}`
      })
    : []

  const alertData: EditAlertData = {
    id: id,
    setTo: alertRowData.setTo ?? "",
    setFrom: alertRowData.setFrom ?? "",
    setAddress: alertRowData.setAddress ?? "",
    contractAbi: contractAbi,
    scEventName: scEventName,
    alertName: alertRowData.alertName ?? "",
    alertType: alertType,
    chainId: chainId,
    outcome: alertRowData.outcome ?? undefined,
    status: status,
    destinations: destinations,
    smartContractEvents: smartContractEventsArray,
  }

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleDeleteUserAlert = () => {
    deleteAlert && deleteAlert(id)
  }

  const open = Boolean(anchorEl)
  const idPopover = open ? 'simple-popover' : undefined

  const [isModalOpen, setIsModalOpen] = useState(false)

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
    handleClose()
  }

  const handleChange = async (_: React.ChangeEvent<HTMLInputElement>) => {
    if(!userToken) return
    let newStatus = UserAlert.Status.DISABLED
    currentStatus === UserAlert.Status.DISABLED
      ? (newStatus = UserAlert.Status.ENABLED)
      : (newStatus = UserAlert.Status.DISABLED)
    const destinationsIds = destinations ? destinations : ['']
    const response = await userService.setAlertStatus(id, newStatus, destinationsIds, alertRowData.alertName ?? "", userToken)
    if (response.status === 'SUCCESS') {
      if (currentStatus === UserAlert.Status.DISABLED) {
        setCurrentStatus(UserAlert.Status.ENABLED)
        addAlert(alertMessages.alerts.alertActive)
      } else {
        setCurrentStatus(UserAlert.Status.DISABLED)
        addAlert(alertMessages.alerts.alertDeactivated)
      }
    }
  }

  return (
    <AlertRowStyle className={isSelect ? 'isSelect' : ''}>
      <Box display={'flex'} gap={1} flexWrap={'wrap'}>
        <Box display={'flex'} gap={1}>
          {alertRowData.icon}
          <AlertType>{alertRowData.alertName}</AlertType>
        </Box>
        {alertRowData.setTo && <BadgeStyle>{alertRowData.setTo}</BadgeStyle>}
        {alertRowData.setFrom && <BadgeStyle>{alertRowData.setFrom}</BadgeStyle>}
        {alertRowData.setAddress && <BadgeStyle>{alertRowData.setAddress}</BadgeStyle>}
        {alertRowData.outcome != undefined &&
          (alertRowData.outcome == 0 ? (
            <SuccessBadgeStyled>Success</SuccessBadgeStyled>
          ) : (
            <FailBadgeStyled>Fail</FailBadgeStyled>
          ))}
      </Box>
      {!isSelect && (
        <Box
          sx={{
            display: 'flex',
            marginLeft: 'auto',
            gap: '9px',
          }}
        >
          <Typography
            sx={{
              color: !currentStatus ? '#FFF' : '#FFFFFF7A',
              fontSize: '16px',
            }}
          >
            {!currentStatus ? 'Active' : 'Disabled'}
          </Typography>
          <MempoolsSwitch checked={!currentStatus} onChange={handleChange} />
          <IconButton
            sx={{
              marginLeft: '10px',
              padding: '0px',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
            onClick={handleClick}
          >
            <MoreHorizIcon
              sx={{
                color: 'rgba(255, 255, 255, 0.48)',
                fontSize: '24px',
                '&:hover': {
                  color: '#61E89F',
                },
              }}
            />
          </IconButton>
          <AlertPopover
            handleClose={handleClose}
            anchorEl={anchorEl}
            open={open}
            id={idPopover}
            onDeleteFn={openModal}
            alertData={alertData}
          />
        </Box>
      )}
      <DeleteConfirmationModal
        open={isModalOpen}
        onCloseModal={closeModal}
        onDelete={handleDeleteUserAlert}
        type="ALERT"
        data={
          <AlertRowStyle
            sx={{
              backgroundColor: 'transparent !important',
              border: '1px solid #FFFFFF1F !important',
            }}
          >
            <AlertType>
              {alertRowData.icon}
              {alertRowData.alertName}
            </AlertType>
          </AlertRowStyle>
        }
      />
    </AlertRowStyle>
  )
}

const SuccessBadgeStyled = styled(Typography)(() => ({
  backgroundColor: '#61E89F3D',
  color: '#61E89F',
  padding: '2px 4px',
  borderRadius: '4px',
}))

const FailBadgeStyled = styled(Typography)(() => ({
  backgroundColor: '#ff5b323b',
  color: '#fd441b',
  padding: '2px 4px',
  borderRadius: '4px',
}))

const AlertRowStyle = styled(Box)(
  () => `
        width: 100%;
        background-color: rgba(255, 255, 255, 0.12);
        border-radius: 4px;
        display: flex;
        flex-wrap: wrap;
        border-radius: 8px;
        padding: 12px;
        color: white;
        display: flex;
        gap: 12px;
      
        &:hover {
          border-color: #61E89F;
          background-color: rgba(255, 255, 255, 0.12);
        }
      
        &.focused {
          border-color: #61E89F;
          box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
          background-color: rgba(255, 255, 255, 0.12);
        }
      
        & input {
            background: rgba(255, 255, 255, 0.12);
          color: 
            rgba(255,255,255,0.65);
          border-radius: 8px;
          padding: 12px;
          height: 40px;
          box-sizing: border-box;
          width: 0;
          min-width: 30px;
          flex-grow: 1;
          border: 0;
          margin: 0;
          outline: 0;
        }

        &.isSelect {
          background-color: transparent;

          &:hover {
            background-color: transparent;
          }

          &.focused {
            background-color: transparent;
          }
        }
      `
)

const AlertType = styled(Typography)(
  () => `
    font-size: 16px;
    display: flex;
    gap: 4px
        `
)

const MempoolsSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(() => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#000000',
      '& + .MuiSwitch-track': {
        backgroundColor: '#61E89F',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: 'black',
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: 'rgba(255, 255, 255, 0.12)',
    opacity: 1,
  },
}))

export const BadgeStyle = styled(Typography)(
  () => `
      background-color: rgba(255, 255, 255, 0.12);
      border-radius: 8px;
      padding: 2px 10px;
      color: white;
      font-size: 14px;
      order: 1 
      `
)
